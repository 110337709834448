import React, { useState, useEffect } from "react";
import { slugify } from "../../lib/utils";
import styled from "styled-components";
import { Link, navigate, useStaticQuery, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, EffectFade, Autoplay, Navigation } from "swiper";
import SEO from "../../components/seo";
import Dialogue from "../../components/dialogue";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import PopoverMenu from "../popover_menu";

import Logo from "../../images/logo.svg";
import Facebook from "../../images/facebook.svg";
import Twitter from "../../images/twitter.svg";
import Instagram from "../../images/instagram.svg";
import Youtube from "../../images/youtube.svg";
import MagnifyingGlass from "../../images/magnifying_glass.svg";
import Burger from "../../images/burger.svg";
import X from "../../images/x.svg";

import NextArrow from "../../images/arrow_circle.png";

const SearchBoxInner = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 0.5rem;
    filter: none !important;
    transform: scale(1.8) !important;
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;

  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    z-index: 1100;
    right: 0;
    width: 50px;
    height: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    background-image: url(${NextArrow});
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    &:after {
      display: none;
    }
  }
`;

const Underlay = styled.div`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.8) 95%
    ),
    linear-gradient(
      222deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0) 100%
    );
  z-index: 400;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 500;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  a {
    display: inline;
    text-decoration: none;
  }
  h1,
  h2 {
    transition: all 0.25s ease-in-out;
    text-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
    margin-left: 120px;
    max-width: 720px;
    @media (max-width: 880px) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
  h2 {
    margin-top: 2rem;
    font-family: "Playfair Display", serif;
    font-style: italic;
  }
  a:hover {
    h1,
    h2 {
      color: var(--pink);
    }
  }
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${(props) => props.image});
  background-position: center center;
  background-size: cover;
  @media (max-width: 768px) {
    background-image: url(${(props) =>
    props.image_mobile ? props.image_mobile : props.image});
  }
`;

const HomeNav = styled.nav`
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  transition: all 0.25s ease-in-out;
  img {
    transition: all 0.25s ease-in-out;
    &.fade {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media (max-width: 880px) {
    left: 3rem;
    top: 26px;
    right: 1.5rem;
  }
  &.open {
    opacity: 0;
  }
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      display: inline-block;
      color: #fff;
      margin-left: 16px;
      img {
        height: 26px;
        -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
      }
      a:hover {
        img {
          opacity: 0.8;
        }
      }
    }
  }
`;

const HomeLogo = styled.div`
  position: absolute;
  top: 30px;
  left: 50px;
  z-index: 1000;
  img {
    width: 100px;
  }
  @media (max-width: 880px) {
    top: 20px;
    left: 1rem;
    img {
      width: 60px;
    }
  }
`;

const HomeFooter = styled.footer`
  position: absolute;
  z-index: 1000;
  color: #fff;
  left: 50px;
  bottom: 30px;
  @media (max-width: 880px) {
    left: 0;
  }
`;

const Headline = styled.h1`
  color: #fff;
  transition: opacity 350ms cubic-bezier(0.47, 0, 0.745, 0.715);
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: auto;
`;

const Byline = styled.h2`
  color: #fff;
  transition: opacity 700ms cubic-bezier(0.47, 0, 0.745, 0.715);
  opacity: ${(props) => (props.visible ? "1" : "0")};
  pointer-events: auto;
`;

const Text = styled.div`
  font-family: Catamaran, sans-serif;
  font-size: 14px;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.6) 0 0 10px;
  a {
    font-family: Catamaran, sans-serif;
    color: var(--pink);
  }
  a.pbj {
    text-decoration: none;
  }
  a span {
    font-weight: bold;
    font-family: Catamaran, sans-serif;
    color: var(--pink);
  }
  @media (max-width: 880px) {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100%;
  }
`;

const Searchbox = styled.div`
  margin-left: 16px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
  @media (max-width: 880px) {
    padding-left: 1.5rem;
    padding-rigth: 0;
  }
  img {
    height: 26px;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    transform: scaleX(-1);
  }
`;

const Burgerbox = styled.div`
  margin-left: 16px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 28px;
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    transform: scaleX(-1);
    cursor: pointer;
  }
`;

const InputSearch = styled.input`
  border: none;
  background-color: transparent;
  text-align: right;
  color: #fff;
  font-family: Catamaran, sans-serif;
  &:focus {
    outline: 0;
  }
`;

const ResultsPopup = styled.ul`
  display: block;
  position: absolute;
  z-index: 9999999999;
  width: 280px;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  transition: all 0.2s linear;
  right: 110px;
  top: 80px;
  padding: 0.7rem;
  margin: 0;
  &.visible {
    opacity: 1;
    transform: translateY(0px);
    li {
      display: inline-block;
    }
  }
  &.hidden {
    background-color: transparent;
    border-color: transparent;
    pointer-events: none;
    transform: translateY(10px);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition-duration: 5ms;
    li {
      display: none;
    }
  }
  @media (max-width: 880px) {
    rigth: 0px;
    top: 109px;
    left: 0px;
    border: none;
    padding: 0;
    width: 100%;
    max-height: calc(100vh - 110px);
    overflow-y: auto;
  }
  li {
    list-style: none;
    font-family: Catemeran, sans-serif;
    padding: 0.5rem 0.7rem 0.3rem 0.7rem;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    color: #817f80;
    font-weight: 300;
    @media (max-width: 880px) {
      padding: 1rem 1rem 0.6rem 1rem;
    }
    &:hover {
      opacity: 0.7;
    }
    span {
      display: block;
      font-family: Catemeran, sans-serif;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: bold;
      color: #000;
      &.agent {
        color: var(--pink);
      }
    }
    &:nth-of-type(odd) {
      background-color: #f8f8f8;
    }
  }
`;

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
      allSanityArtist(sort: { fields: first_name }) {
        edges {
          node {
            surname
            first_name
          }
        }
      }
      allSanityPerson(sort: { fields: title }) {
        edges {
          node {
            slug {
              current
            }
            title
            email
            pbj_staff
          }
        }
      }
      allSanityHomepage {
        edges {
          node {
            articles {
              id
              title
              date
              homepage_image {
                asset {
                  url
                  localFile(width: 3480, format: JPG) {
                    publicURL
                  }
                }
              }
              homepage_image_mobile {
                asset {
                  url
                  localFile(width: 3840, format: JPG) {
                    publicURL
                  }
                }
              }
              image {
                asset {
                  url
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [headline, setHeadline] = useState("");
  const [byline, setByline] = useState("");
  const [storyLink, setStoryLink] = useState("");
  const [visible, setVisible] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchterm, setSearchterm] = useState("");
  const [searchresults, setSearchresults] = useState(false);
  const [searchresultsartists, setSearchresultsartists] = useState(false);
  const [searchresultsagents, setSearchresultsagents] = useState(false);
  const [searchVisibleMobile, setSearchVisibleMobile] = useState(false);

  const isBrowser = typeof window !== "undefined";

  const all_page_links = data.allSitePage.edges.map((p) => p.node.path);

  const all_artists = data.allSanityArtist.edges.map((a) => {
    let artists = a.node.first_name;
    if (a.node.surname) {
      artists = artists + " " + a.node.surname;
    }
    return artists.trim();
  });

  const all_agents = data.allSanityPerson.edges
    .map((a) => {
      if (a.node.pbj_staff) {
        return a.node.title;
      } else {
        return false;
      }
    })
    .filter(Boolean);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeSearch();
      }
    };
    if (isBrowser) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener("keydown", handleEsc);
      }
    };
  }, []);

  useEffect(() => {
    if (searchterm && searchterm.length > 1) {
      const results_artists = all_artists.filter((a) =>
        a.toLowerCase().includes(searchterm.toLowerCase()),
      );
      const results_agents = all_agents.filter((a) =>
        a.toLowerCase().includes(searchterm.toLowerCase()),
      );
      const results = [...results_artists, ...results_agents];

      if (searchterm.length > 0 && results_artists.length > 0) {
        setSearchresultsartists(results_artists);
      } else {
        setSearchresultsartists(false);
      }

      if (searchterm.length > 0 && results_agents.length > 0) {
        setSearchresultsagents(results_agents);
      } else {
        setSearchresultsagents(false);
      }

      if (searchterm.length > 0 && results.length > 0) {
        setSearchresults(results);
      } else {
        setSearchresults(false);
      }
    }
  }, [searchterm]);

  const unfiltered_slides = data.allSanityHomepage.edges[0].node.articles;

  const slides = unfiltered_slides.filter((s) => s.image !== null);

  const closeSearch = () => {
    setSearchterm("");
    setSearchresults(false);
    setSearchVisible(false);
  };

  const goto = (url) => {
    setSearchterm("");
    setSearchresults(false);
    setSearchVisible(false);
    navigate(url);
  };

  const nth = (d) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const dateify = (date) => {
    if (date.length > 1) {
      const date_parts = date.split("-");
      const year = date_parts[0];
      const month = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][parseInt(date_parts[1])];
      const day = date_parts[2] + nth(date_parts[2]);

      const int_day = parseInt(day);

      return int_day + " " + month + " " + year;
    } else {
      return "";
    }
  };

  const handleSlideChange = (e) => {
    let i;
    if (e.activeIndex > slides.length) {
      i = 0;
    } else {
      i = e.activeIndex - 1;
    }

    setTimeout(() => {
      setHeadline(slides[i].title);
      setStoryLink("/news/" + slugify(slides[i].title));
      setByline(dateify(slides[i].date));
    }, 250);
  };

  const year = new Date().getFullYear();

  return (
    <Container>
      <SEO
        title="PBJ Management"
        description="PBJ Management represents a select group of comedy performers, presenters, actors, writers, composers, directors, producers and broadcasters."
        slug="/"
      />

      <PopoverMenu
        all_page_links={all_page_links}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
      />

      <ResultsPopup
        className={
          searchresults &&
            searchresults.length > 0 &&
            (searchVisible || searchVisibleMobile)
            ? "vislble"
            : "hidden"
        }
      >
        {searchresultsartists &&
          searchresultsartists.map((a, index) => {
            const url = "/artists/" + slugify(a);
            return (
              <li key={index} onClick={() => goto(url)}>
                <span>Artist</span> {a}
              </li>
            );
          })}
        {searchresultsagents &&
          searchresultsagents.map((a, index) => {
            const url = "/people/" + slugify(a);
            return (
              <li key={index} onClick={() => goto(url)}>
                <span className="agent">PBJ Agent</span> {a}
              </li>
            );
          })}
      </ResultsPopup>

      <HomeLogo>
        <img src={Logo} alt="PBJ" />
      </HomeLogo>

      <HomeNav className={menuVisible ? "open" : "closed"}>
        <ul>
          <li>
            <a href="https://www.instagram.com/pbjmanagement">
              <img className="instagram" src={Instagram} alt="Instagram" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/pbjmanagement">
              <img className="twitter" src={Twitter} alt="Twitter" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/pbjmanagement">
              <img className="youtube" src={Youtube} alt="Youtube" />
            </a>
          </li>
        </ul>
        <Searchbox>
          {!searchVisible && (
            <img
              src={MagnifyingGlass}
              className="fade"
              onClick={() => setSearchVisible(true)}
              alt="search"
            />
          )}
          {searchVisible && (
            <SearchBoxInner>
              <InputSearch
                autoComplete="off"
                role="presentation"
                autoFocus
                name="search"
                value={searchterm}
                onChange={(e) => setSearchterm(e.target.value)}
              />
              <img
                src={X}
                className="fade"
                alt="close"
                onClick={() => closeSearch()}
              />
            </SearchBoxInner>
          )}
        </Searchbox>
        <Burgerbox>
          <img
            src={Burger}
            className="fade"
            alt="menu"
            onClick={() => setMenuVisible(true)}
          />
        </Burgerbox>
      </HomeNav>

      <Underlay></Underlay>

      <Overlay visible={visible}>
        <Link to={storyLink}>
          <Headline visible={visible}>{headline}</Headline>
        </Link>
      </Overlay>

      <Swiper
        navigation={true}
        spaceBetween={0}
        slidesPerView={1}
        className="pbj-slider"
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Keyboard, EffectFade, Autoplay, Navigation]}
        effect="fade"
        speed={1000}
        fadeEffect={{
          crossFade: true,
        }}
        onSlideChange={(e) => handleSlideChange(e)}
        onSlideChangeTransitionStart={() => setVisible(false)}
        onSlideChangeTransitionEnd={() => setVisible(true)}
      >
        {slides.map((s, index) => {
          if (s.image !== null) {
            const slide_image = s?.homepage_image?.asset?.localFile?.publicURL
              ? s.homepage_image.asset.localFile.publicURL
              : s.image.asset.localFile.publicURL;
            console.log(slide_image);
            const slide_image_mobile = s?.homepage_image_mobile?.asset
              ?.localFile?.publicURL
              ? s.homepage_image_mobile.asset.localFile.publicURL
              : false;

            return (
              <SwiperSlide key={index}>
                <Slide
                  image={slide_image}
                  image_mobile={slide_image_mobile}
                ></Slide>
              </SwiperSlide>
            );
          }
        })}
      </Swiper>

      <Dialogue />

      <HomeFooter>
        <Text>
          PBJ.{" "}
          <Link className="pbj" to="/">
            <span>Exclusive talent agency</span>
          </Link>
          . | Copyright &copy; 2015-{year} PBJ Management |{" "}
          <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
          <Link to="/cookie-policy">Cookie Policy</Link>
          <br />
          All images and text on this site are strictly under copyright. No
          unauthorsed reproduction. <Link to="/terms">View terms</Link>.
        </Text>
      </HomeFooter>
    </Container>
  );
};

export default Home;
