import React from "react";
import Home from '../components/home'
import SEO from '../components/seo'

const HomePage = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default HomePage;
