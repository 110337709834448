import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const D = styled.div`
  width: 400px;
  border-radius: 25px;
  background-color: #fff;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 400;
  padding: 1.5rem 1.5rem;
  form {
    margin-top: 1rem;
  }
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  h3 {
    font-family: "Playfair Display", serif;
    font-size: 28px;
  }
  label {
    font-family: 'Catamaran', sans-serif;
  }
  div.mc-field-group {
    display: flex;
    flex-direction: column;
  }
  div#mc_embed_signup_scroll {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  div.clear {
    display: flex;
    column-gap: 16px;
  }
  input {
    font-size: 16px;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #aaa;
    &.ghost {
      border: 1px solid var(--pink);
      color: var(--pink);
      background-color: #fff;
      font-family: Catamaran, sans-serif;
      padding: 8px 16px;
      cursor: pointer;
    }          
  }
  input[type=submit] {
    &:disabled {
      opacity: 0.5;
    }
    background-color: var(--pink);
    color: #fff;
    border: 1px solid var(--pink);
    padding: 8px 16px;
    font-family: Catamaran, sans-serif;
    border-radius: 8px;
    cursor: pointer;      
  }
  .optin {
    display: flex;
    column-gap: 8px;
    align-items: flex-start;
    p {
      font-size: 16px;
      line-height: 1.3em;
    }
    input {
      margin-top: 2px;
    }
  }
  .fineprint p {
    font-size: 12px;
    line-height: 1.4em;
    a {
      color: var(--pink);
      font-weight: light;
    }
  }
`

const Dialogue = () => {
  const [agreed, setAgreed] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [showSignup, setShowSignup] = useState(true);

  useEffect(() => {
    const optOut = localStorage.getItem('optOutNewsletter');
    if (optOut) {
      setShowSignup(false);
    }
  }, []);

  const handleNoThanksClick = () => {
    localStorage.setItem('optOutNewsletter', 'true');
    setShowSignup(false);
  };

  if (!showSignup) {
    return null; // or render something else
  }

  return (
    <D>
      <h3>Sign up to our newsletter</h3>
      <form action="https://kbjmanagement.us14.list-manage.com/subscribe/post?u=636342a56cd3f5661be64328e&amp;id=e7aebdddec&amp;f_id=00c6b6e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
        <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <label for="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="mc-field-group">
              <label for="mce-FNAME">First Name </label>
              <input type="text" name="FNAME" className=" text" id="mce-FNAME" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className="mc-field-group">
              <label for="mce-LNAME">Last Name </label>
              <input type="text" name="LNAME" className=" text" id="mce-LNAME" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
          <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
            <input type="text" name="b_636342a56cd3f5661be64328e_e7aebdddec" tabindex="-1" />
          </div>
          <div className="optin">
            <input type="checkbox" name="optin" checked={agreed} onClick={() => setAgreed(!agreed)} />
            <p>Select this box to opt-in to PBJ Management's newsletter.</p>
          </div>
          <div className="clear">
            <input disabled={!agreed} type="submit" name="subscribe" id="mc-embedded-subscribe" value="Subscribe" />
            <input className="ghost" type="button" name="noThanks" id="mc-embedded-no-thanks" value="No thanks" onClick={handleNoThanksClick} />
          </div>
          <div className="fineprint">
            <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information, please read our <a href="https://pbjmanagement.co.uk/privacy-policy">privacy policy</a>.</p>
            <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/terms">Learn more</a> about Mailchimp's privacy practices.</p>
          </div>
        </div>
      </form>
    </D>
  )
}

export default Dialogue
